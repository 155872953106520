import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Grid} from "@mui/material";
import { CustomerService } from "../../services";
import SearchBox from "../../sections/SearchBox";
import CustomerCard from "../../sections/CustomerCard";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";

function Customers() {
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const navigations = {
    title: "CUSTOMERS",
    items: [{ name: "CUSTOMERS" }], 
    navItems: [
      { id: "new", title: "NEW", image: "plus.png" }
    ],
  };

  useEffect(() => {
    loadItems();
  }, [page]);

  const loadItems = async () => {
    try {
      const result = await CustomerService.getAll(page, 20);
      setCustomers((prevStocks) => [...prevStocks, ...result.data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function navBarAction(id){
    if(id === "new"){
      navigate("/customers/create");
    }
    }


  async function makeSearch(searchText) {
    // Implement the search functionality here
  }

  const navigate = useNavigate();

  return (
    <>
      <NavBreadcrumbs navs={navigations}  callback={navBarAction}/>

      <SearchBox
        callback={(searchText) => makeSearch(searchText)}
        onCancel={async () => {
          setPage(0);
          setCustomers([]);
          loadItems();
        }}
      />

      <Grid sx={{ mt: 3 }} container spacing={3}>
        {customers.map((customer, index) => (
          <CustomerCard key={index} customer={customer} />
        ))}
      </Grid>
    </>
  );
}

export default Customers;
