import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Paper, TextField, Typography, Button } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { CustomerService } from "../../services/customer.services";
import { Translate } from "../../i18n/translate";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";
import { AlertDialog } from "../../helpers/helper.alertDialog";

export default function UpdateStock() {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phone: "",
    address: "",
    postCode: "",
    city: "",
    country: ""
  });


  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (customerId !== undefined) {
      fetchStock();
    }
  }, []);

  const navigations = {
    title: customerId === undefined ? "CREATE_CUSTOMER" : "UPDATE_CUSTOMER",
    items: [
      { name: "CUSTOMERS", link: "/customers" }, 
      { name: customerId === undefined ? "CREATE_CUSTOMER" : "UPDATE_CUSTOMER" },
    ],
    navItems: [{ id: "save", title: "SAVE", image: "save.png" }],
  };
  

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrors({ ...errors, [prop]: event.target.value ? "" : "This field is required" });
  };

  async function fetchStock() {
    try {
      const result = await CustomerService.get(customerId);
      let customer = result.data;
      setValues({
        firstName: customer.firstName,
        lastName: customer.lastName,
        companyName: customer.companyName,
        email: customer.email,
        phone: customer.phone,
        address: customer.billing_address.address,
        postCode: customer.billing_address.postCode,
        city: customer.billing_address.city,
        country: customer.billing_address.country,
      });



    } catch (error) {
      const errorMessage =
        error.response?.data?.message?.message ||
        "An error occurred while fetching stock data.";
      AlertDialog.showAlert(errorMessage);
    }
  }

  async function submit() {
    try {
      if (!validateForm()) return;
      if (customerId === undefined) {
        const saveResult = await CustomerService.save(values);
        const newID = saveResult.data._id;
        navigate(`/customers/${newID}`);
      } else {
        await CustomerService.update(customerId, values);
        navigate(`/customers/${customerId}`);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while processing your request.";
      AlertDialog.showAlert(Translate.translate("ERROR"), errorMessage);
    }
  }

  const validateForm = () => {
    const newErrors = {};
  
    // Check only firstName and lastName fields
    if (!values.firstName) {
      newErrors.firstName = "First name is required";
    }
    if (!values.lastName) {
      newErrors.lastName = "Last name is required";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <>
      <NavBreadcrumbs navs={navigations} callback={submit} />
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          {Translate.translate(customerId === undefined ? "CREATE_CUSTOMER" : "UPDATE_CUSTOMER")}
        </Typography>
        <Grid container spacing={3}>
          {/* Personal Information Section */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              {Translate.translate("PERSONAL_INFORMATION")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={Translate.translate("CUSTOMER_FIRST_NAME")}
              fullWidth
              value={values?.firstName}
              onChange={handleChange("firstName")}
              error={!!errors.firstName}
              helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={Translate.translate("CUSTOMER_LAST_NAME")}
              fullWidth
              value={values?.lastName}
              onChange={handleChange("lastName")}
              error={!!errors.lastName}
              helperText={errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={Translate.translate("COMPANY_NAME")}
              fullWidth 
              value={values?.companyName}
              onChange={handleChange("companyName")}
             
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label={Translate.translate("EMAIL")}
              fullWidth
              value={values?.email}
              onChange={handleChange("email")} 
           
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label={Translate.translate("PHONE")}
              fullWidth
              type="number"
              value={values?.phone}
              onChange={handleChange("phone")}
              InputProps={{
                startAdornment: <InputAdornment position="start">☎️</InputAdornment>,
              }} 
 
            />
          </Grid>
          

          {/* Address Section */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              {Translate.translate("ADDRESS_INFORMATION")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label={Translate.translate("CUSTOMER_ADDRESS")}
              fullWidth
              value={values?.address}
              onChange={handleChange("address")}  
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label={Translate.translate("CUSTOMER_ZIP")}
              fullWidth
              value={values?.postCode}
              onChange={handleChange("postCode")}  
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label={Translate.translate("CUSTOMER_CITY")}
              fullWidth
              value={values?.city}
              onChange={handleChange("city")}  
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label={Translate.translate("CUSTOMER_COUNTRY")}
              fullWidth
              value={values?.country}
              onChange={handleChange("country")}  
            />
          </Grid>

      
        </Grid>
      </Paper>
    </>
  );
}
