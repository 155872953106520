const CustomersLang = {
  CREATE_CUSTOMER: {
    en: "Create Customer",
    tr: "Müşteri Oluştur",
  },
  UPDATE_CUSTOMER: {
    en: "Update Customer",
    tr: "Müşteri Güncelle",
  },
  PERSONAL_INFORMATION: {
    en: "Personal Information",
    tr: "Kişisel Bilgiler",
  },
  CUSTOMER_FIRST_NAME: {
    en: "First Name",
    tr: "Ad",
  },
  CUSTOMER_LAST_NAME: {
    en: "Last Name",
    tr: "Soyad",
  },
  COMPANY_NAME: {
    en: "Company Name",
    tr: "Şirket Adı",
  },
  EMAIL: {
    en: "Email",
    tr: "E-posta",
  },
  PHONE: {
    en: "Phone",
    tr: "Telefon",
  },
  ADDRESS_INFORMATION: {
    en: "Address Information",
    tr: "Adres Bilgileri",
  },
  CUSTOMER_ADDRESS: {
    en: "Address",
    tr: "Adres",
  },
  CUSTOMER_ZIP: {
    en: "Postal Code",
    tr: "Posta Kodu",
  },
  CUSTOMER_CITY: {
    en: "City",
    tr: "Şehir",
  },
  CUSTOMER_COUNTRY: {
    en: "Country",
    tr: "Ülke",
  },
  NEW_CUSTOMER: {
    en: "New Customer",
    tr: "Yeni Müşteri",
  },
};

const CombinedCustomers = { ...CustomersLang };

export default CombinedCustomers;
