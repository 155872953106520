import { ServiceManager } from "./serviceManager";

export const CustomerService = {
  getAll,
  get,
  getOrders,
  update,
  save
};

function getAll(page = 0, limit = 10) {
  return ServiceManager.Get(`customers?limit=${limit}&page=${page}`);
}
function get(idCustomer) {
  return ServiceManager.Get(`customers/${idCustomer}`);
}
function getOrders(idCustomer) {
  return ServiceManager.Get(`customers/${idCustomer}/orders`);
}
function update(id, payload) {
  let body = {
    firstName: payload?.firstName ?? "",
    lastName: payload?.lastName ?? "",
    companyName: payload?.companyName ?? "",
    email: payload?.email ?? "",
    phone: payload?.phone ?? "",
    billing_address: {
      address: payload?.address ?? "",
      postCode: payload?.postCode ?? "",
      city: payload?.city ?? "",
      country: payload?.country ?? "",
    },
  };
  return ServiceManager.Put(`customers/${id}`, body);
}

function save(payload) {
  let body = {
    firstName: payload?.firstName ?? "",
    lastName: payload?.lastName ?? "",
    companyName: payload?.companyName ?? "",
    email: payload?.email ?? "",
    phone: payload?.phone ?? "",
    billing_address: {
      address: payload?.address ?? "",
      postCode: payload?.postCode ?? "",
      city: payload?.city ?? "",
      country: payload?.country ?? "",
    },
  };
  return ServiceManager.Post("customers", body);
}
 